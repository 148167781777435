import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiCommonResponse } from '../interfaces/responses/common/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class QrService {

  url: string = environment.url;

  constructor(
    private http: HttpClient,
  ) { }

  generar(uuid : string) {
    return this.http.post<ApiCommonResponse & { qr_code: string, qr_uuid: string }>(this.url + 'plataforma_pagos/qr/generar', { uuid })
  }

  consultar(uuid: string, qr_uuid: string) {
    return this.http.get<ApiCommonResponse & { pagado: 0 | 1, movimiento: string | null, total: number | null }>(this.url + `plataforma_pagos/qr/consultar`, { params: { uuid, qr_uuid } });
  }
}
