import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';
import { IOrden } from '../models/orden.model';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {

  urlCompartirQr = environment.urlCompartirQr;
  url: string = environment.url;

  constructor(
    private http: HttpClient,
  ) { }

  private generarDeepLink(orden : IOrden, descripcion?: string): string {
    let linkPago = this.urlCompartirQr + "?cuit=" + orden.comercio.cuit;
    linkPago += '&cvu=' + orden.comercio.cvu;
    linkPago += '&importe=' + orden.importe;
    if (orden.concepto) linkPago += '&concepto=' + orden.concepto;
    if (descripcion) {
      const codec = new HttpUrlEncodingCodec();
      linkPago += '&descripcion=' + codec.encodeValue(descripcion);
    }
    //linkPago += '&app_name=' + appName;

    return linkPago;
  }

  abrirDeepLink(orden : IOrden){
    const deepLink = this.generarDeepLink(orden);
    const downloadLink = document.createElement("a");
    downloadLink.href = deepLink;
    downloadLink.target = '_blank';
    downloadLink.click();
    downloadLink.remove();
  }

}
