<!-- <div class="d-flex flex-column justify-content-center" style="height: 100vh;">
  <div class="mx-auto">
    <h5 class="text-secondary">Link de pago inválido.</h5>
  </div>
</div> -->
<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <div class="card-body">
      <div class="box-body mt-3">

        <h3 class="text-primary">{{ title }}</h3>
        <div class="text-secondary" [innerHTML]="subtext"></div>


      </div>
    </div>



  </div>

  <app-footer></app-footer>

</div>
