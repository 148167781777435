{
  "name": "telepagos-plataforma-pagos",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "compilar-dev": "node dist/vaciarCarpetaOrigen.mjs && ng build --configuration=desarrollo && node dist/copiarCarpetaDestino.mjs dev && node dist/vaciarCarpetaOrigen.mjs",
    "compilar-homo": "node dist/vaciarCarpetaOrigen.mjs && ng build --configuration=homologacion && node dist/copiarCarpetaDestino.mjs homo && node dist/vaciarCarpetaOrigen.mjs",
    "compilar-prod": "node dist/vaciarCarpetaOrigen.mjs && ng build --configuration=production && node dist/copiarCarpetaDestino.mjs prod && node dist/vaciarCarpetaOrigen.mjs",
    "compilar-dev-homo": "npm run compilar-dev && npm run compilar-homo",
    "compilar-all": "npm run compilar-dev && npm run compilar-homo && npm run compilar-prod"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.10",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "angularx-qrcode": "^17.0.1",
    "bootstrap": "4.6.x",
    "ngx-mask": "^17.0.8",
    "ngx-toastr": "^19.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.0",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
