<div class="card-body">

  <div class="box-body mt-3">

    <!-- INICIO -->
    <ng-container *ngIf="step == 0">
      <app-orden-info [orden]="orden" *ngIf="step == 0"></app-orden-info>
      <form class="form" [formGroup]="form">
        <div class="row">
          <div class="col-lg-4 col-xl-3">
            <div class="form-group">
              <label> Ingresá el <strong>CPF</strong> o <strong>CNPJ</strong> de <strong>quien va a pagar</strong>:</label>
              <input required type="number" name="cpf" class="form-control" formControlName="cpf" mask="99999999999999"
                [class.is-invalid]="(!formCpf.valid && (formCpf.touched || !formCpf.pristine ))">
              <div class="text-danger" *ngIf="(!formCpf.valid && (formCpf.touched || !formCpf.pristine ))">
                <small *ngIf="formCpf.errors['required']">* Campo requerido.</small>
                <small *ngIf="formCpf.errors['pattern']">* Formato inválido.</small>
                <small *ngIf="formCpf.errors['minlength']">* Mínimo 11 dígitos.</small>
                <small *ngIf="formCpf.errors['maxlength']">* Máximo 14 dígitos.</small>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-xl-3" id="btn-main">
            <button [disabled]="!(form.valid)" (click)="generarQrCerrado()" class="btn btn-primary w-100">
              Pagar
            </button>
          </div>
        </div>

        <div class="row mt-3" id="cancelar-row-1">
          <div class="col-lg-4 col-xl-3 ">
            <button (click)="clickVolver.emit(); step = 0" class="btn btn-outline-primary w-100">
              Volver
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <!-- EN ESPERA -->
    <ng-container *ngIf="step == 1">

      <div class="row">
        <div class="col-12 mb-4">
          <div class="mb-1">
            <span style="width: 100%; display: inline-block;"> <strong class="mr-1"> Titular: </strong> {{orden.comercio.razon_social}} </span>
          </div>
          <div class="mb-1">
            <span style="width: 100%; display: inline-block;"> <strong class="mr-1"> Concepto: </strong> {{orden.concepto_descripcion}}</span>
          </div>
          <div class="mb-5">
            <span style="width: 100%; display: inline-block;"> <strong> Importe a pagar:</strong> {{ orden.importe | monto }}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-xl-3">

          <div class="d-flex flex-column align-items-center">
            <ng-container *ngIf="datosPix?.qrcode">
              <h4 class="text-primary text-center" style="font-weight: 700;"><span
                  style="font-weight: 400;">R</span><span class="importe">{{ datosPix?.total | monto }}</span></h4>
              <qrcode [qrdata]="datosPix?.qrcode" [width]="width" [errorCorrectionLevel]="'M'" [elementType]="'img'"
                [class.qr-disabled]="qrDisabled"></qrcode>
            </ng-container>

            <div *ngIf="!datosPix?.qrcode" [ngStyle]="{ 'width' : width + 'px' , 'height' : width + 'px' }"
              class="d-flex justify-content-center align-items-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Cargando QR...</span>
              </div>
            </div>

            <button (click)="copiarQR()" class="btn btn-primary w-100 my-3 ">Copiar QR
            </button>
            <!-- <div class="w-100">
            </div> -->

            <app-timer (accion)="timerFinalizado($event)" [duracion]="datosPix?.expires_in"></app-timer>

          </div>
        </div>

      </div>


      <div class="row mt-3">
        <div class="col-lg-4 col-xl-3">
          <button *ngIf="qrDisabled" (click)="generarQrCerrado()" class="btn btn-primary w-100 mt-2">Generar Nuevo QR
          </button>
        </div>
        <div class="col-lg-4 col-xl-3">
          <button *ngIf="!qrDisabled" class="btn btn-primary w-100 mt-2" style="visibility: hidden;">xx </button>
        </div>
      </div>

      <div class="row mt-3" id="cancelar-row-2">
        <div class="col-lg-4 col-xl-3">
          <button (click)="reiniciarComponente()" class="btn btn-outline-primary w-100">
            Volver
          </button>
        </div>
      </div>

    </ng-container>

    <!-- EXITO -->
    <ng-container *ngIf="step == 2">
      <div class="row mt-3" id="mensaje-exito">
        <div class="col-lg-4 col-xl-3">
          <h4 class="text-primary mb-5"><b>Pago exitoso!</b></h4>
          <h4 class="text-primary importe">{{movimiento?.monto | monto}}</h4>
          <h6 class="" >N° de movimiento: <b>{{movimiento?.id}}</b></h6>
        </div>
      </div>

      <!-- <div class="row mt-5">
        <div class="col-lg-4 col-xl-3">
          <button (click)="reiniciarComponente()"
          class="btn btn-outline-primary w-100">
          Volver
        </button>
        </div>
      </div> -->

    </ng-container>

  </div>

</div>
