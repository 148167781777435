import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrdenesCobroService } from '../../services/ordenes-cobro.service';
import { IOrden } from '../../models/orden.model';
import { OrdenInfoComponent } from '../orden-info/orden-info.component';
import { CommonModule } from '@angular/common';
import { QrService } from '../../services/qr.service';
import { QRCodeModule } from 'angularx-qrcode';
import { HttpErrorResponse } from '@angular/common/http';
import { MensajesService } from '../../services/mensajes.service';
import { Subscription, timer } from 'rxjs';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';

@Component({
  selector: 'app-qr-estandar',
  standalone: true,
  imports: [
    CommonModule,
    OrdenInfoComponent,
    QRCodeModule,
    SharedPipesModule
  ],
  templateUrl: './qr-estandar.component.html',
  styleUrl: './qr-estandar.component.scss'
})
export class QrEstandarComponent implements OnInit {
  @Input('orden') orden!: IOrden;
  @Output() clickVolver = new EventEmitter<void>();

  public step = StepsQr.QR;

  public qrCode: string = "";
  public qrUuid: string = "";
  public width = 292;

  public consultaQRTimer: Subscription | null = null;

  public importeAsignado: number | null = null;
  public movimiento: string | null = null;
  public total: number | null = null;

  constructor(
    private qrService: QrService,
    private mensajesService: MensajesService
  ) {

  }

  ngOnInit(): void {
    this.qrService.generar(this.orden.uuid).subscribe((res) => {
      if (res.status == 'ok') {
        this.qrCode = res.qr_code;
        this.qrUuid = res.qr_uuid;

        if (this.qrUuid) {
          if (this.consultaQRTimer != null) {
            this.consultaQRTimer.unsubscribe();
          }
          this.consultaQRTimer = timer(2000, 5000).subscribe(() => {
            this.consultar()
          })
        }
      }
      else {
        this.mensajesService.mensajeError(res.mensaje_error || 'Ha ocurrido un error.');
      }
    },
      (err: HttpErrorResponse) => {
        const error = err.error;
        this.mensajesService.mensajeError(error.mensaje_error || 'Ha ocurrido un error.');
      })
  }

  consultar() {
    this.qrService.consultar(this.orden.uuid, this.qrUuid).subscribe((res) => {
      if (res.status == 'ok') {
        if (res.pagado) {
          if (this.consultaQRTimer !== null) {
            this.consultaQRTimer.unsubscribe();
          }
          this.movimiento = res.movimiento;
          this.total = res.total;
          this.step = StepsQr.EXITO;
        }
      } else {
        this.mensajesService.mensajeError(res.mensaje_error || 'Ha ocurrido un error.')
      }
    })
  }

  volver() {
    if (this.consultaQRTimer !== null) {
      this.consultaQRTimer.unsubscribe();
    }
    this.clickVolver.emit();
  }

}

enum StepsQr {
  QR = 0,
  EXITO = 1
}
