import { HttpClient } from '@angular/common/http';
import { Component, NgModule } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { environment } from '../../environments/environment';
import { OrdenesCobroService } from '../services/ordenes-cobro.service';
import { IOrden } from '../models/orden.model';
import { TransferenciaComponent } from './transferencia/transferencia.component';
import { QrPixComponent } from './qr-pix/qr-pix.component';
import { QrEstandarComponent } from './qr-estandar/qr-estandar.component';
import { OrdenInfoComponent } from './orden-info/orden-info.component';
import { QrService } from '../services/qr.service';
import { DeeplinkService } from '../services/deeplink.service';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    CommonModule,
    TransferenciaComponent,
    QrPixComponent,
    QrEstandarComponent,
    OrdenInfoComponent,
    FooterComponent,
    HeaderComponent
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
})
export class CheckoutComponent {

  public orden : IOrden | null = null;
  public metodoPago = MetodosPago.INDEFINIDO;

  constructor(
    private route : ActivatedRoute,
    public ordenService : OrdenesCobroService
  ){

  }

  async ngOnInit(){
    const urlSegments = await firstValueFrom(this.route.url)
    const uuid = urlSegments[0]?.path || '';
    this.orden = await this.ordenService.obtener(uuid);
  }

  get MetodosPago(){
    return MetodosPago
  }
}

enum MetodosPago {
  INDEFINIDO = 0,
  QR_PIX = 1,
  TRANSFERENCIA = 2,
  QR_ESTANDAR = 3,
}
