import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CuitPipe } from './cuit.pipe';
import { DocumentoPipe } from './documento.pipe';
import { FormatTimePipe } from './format-time.pipe';
import { MontoPipe } from './monto.pipe';
import { NumeroPipe } from './numero.pipe';



@NgModule({
  declarations: [
    CuitPipe,
    DocumentoPipe,
    FormatTimePipe,
    MontoPipe,
    NumeroPipe
  ],
  imports: [
  ],
  exports: [
    CuitPipe,
    DocumentoPipe,
    FormatTimePipe,
    MontoPipe,
    NumeroPipe
  ]
})
export class SharedPipesModule { }
