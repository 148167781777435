<div class="row">
  <div class="col-12 mb-4">
    <div class="mb-1">
      <span style="width: 100%; display: inline-block;"> <strong class="mr-1"> Titular: </strong> {{orden.comercio.razon_social}} </span>
    </div>
    <div>
      <span style="width: 100%; display: inline-block;"> <strong class="mr-1"> Concepto: </strong> {{orden.concepto_descripcion}}</span>
    </div>
  </div>
  <div class="col-12">
    <div class="mb-3">
      <span style="width: 100%; display: inline-block;"> <strong> Importe a pagar:</strong> </span>
      <h5 class="text-primary" style="font-weight: 700; font-size:1.4rem; display: inline-block;" id="importe">{{orden.importe | monto }} </h5>
    </div>
  </div>
</div>
