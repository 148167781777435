import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { IOrden } from '../models/orden.model';

@Injectable({
  providedIn: 'root'
})
export class QrPixService {

  url: string = environment.url;

  constructor(
    private http: HttpClient,
  ) { }

  generarQrPIX(uuid: string, documento: string) {
    return this.http.post<any>(this.url + 'plataforma_pagos/qr/pix', { documento , uuid });
  }

  estadoQrPIX(id: string, uuid: string) {
    return this.http.get<any>(this.url + 'plataforma_pagos/qr/pix/estado', { params: { id , uuid } });
  }
}
