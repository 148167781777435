import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumber]'
})
export class OnlyNumberDirective {

  regexStr = '^[0-9]*$';
  constructor(private el : ElementRef) { }

  @Input() OnlyNumber: boolean = false;

  @HostListener('keydown', ['$event']) onKeyDown(event : Event) {
    let e = <KeyboardEvent> event;

    if (this.OnlyNumber) {
      // Permite Tab y Esc
      if (['Tab', 'Escape','Backspace'].indexOf(e.key) !== -1 ||
      // Permite: Ctrl+C
      ((e.key === 'c' || e.key === 'C') && (e.ctrlKey || e.metaKey)) ||
      // Permite: Ctrl+V
      ((e.key === 'v' || e.key === 'V') && (e.ctrlKey || e.metaKey)) ||
      // Permite: Ctrl+X
      ((e.key === 'x' || e.key === 'X') && (e.ctrlKey || e.metaKey))) {
      // No hacer nada
        return;
      }
      // Si cumple el regex
      let regEx =  new RegExp(this.regexStr);
      if(regEx.test(e.key))
        return; //No hace nada
      else
        e.preventDefault(); //Detiene el evento
    }

  }

}
