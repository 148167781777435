import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

  constructor(
    private toastr : ToastrService
  ) { }

  private commonCustomPartial: Partial<IndividualConfig> = {
    timeOut: 2000,
    positionClass: 'toast-custom-position',
    toastClass: 'ngx-toastr ngx-custom-toastr'
  };

  public mensajeExito(mensaje :string, titulo? :string){
    this.toastr.success(mensaje, titulo, this.commonCustomPartial);
  }

  public mensajeExitoFijo(mensaje :string, titulo? :string){
    this.toastr.success(mensaje, titulo, { ... this.commonCustomPartial , timeOut : 0 , extendedTimeOut : 0});
  }

  public mensajeError(mensaje :string, titulo? :string){
    this.toastr.error(mensaje, titulo, this.commonCustomPartial);
  }

  public mensajeErrorFijo(mensaje :string, titulo? :string){
    this.toastr.error(mensaje, titulo, { ... this.commonCustomPartial , timeOut : 0 , extendedTimeOut : 0});
  }
}
