import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrdenInfoComponent } from '../orden-info/orden-info.component';
import { IOrden } from '../../models/orden.model';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { cuitPattern } from '../../helpers/patterns';
import { MensajesService } from '../../services/mensajes.service';
import { OrdenesCobroService } from '../../services/ordenes-cobro.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxMaskDirective } from 'ngx-mask';
import { DeeplinkService } from '../../services/deeplink.service';

@Component({
  selector: 'app-transferencia',
  standalone: true,
  imports: [
    CommonModule,
    OrdenInfoComponent,
    ReactiveFormsModule,
    SharedDirectivesModule,
    NgxMaskDirective
  ],
  templateUrl: './transferencia.component.html',
  styleUrl: './transferencia.component.scss'
})
export class TransferenciaComponent {
  @Input('orden') orden! : IOrden;
  @Output() clickVolver = new EventEmitter<void>();

  public form!: FormGroup;
  public step = StepTransferencia.INICIO;

  constructor(
    private fb: FormBuilder,
    private mensajesService : MensajesService,
    private ordenesService : OrdenesCobroService,
    private deeplink : DeeplinkService
  ) {
    this.form = this.fb.group({
      cuit: ['', [Validators.required, Validators.pattern(cuitPattern)]],
    });
  }

  copiarAlias(){
    navigator.clipboard.writeText(this.orden.comercio.alias).then(()=>{
      this.mensajesService.mensajeExito("Copiado al portapapeles.");
    })
  }

  declararCuit(){
    this.ordenesService.setCuit(this.orden.uuid , this.formCuit?.value).subscribe((res)=>{
      if(res.status == 'ok'){
        this.step = StepTransferencia.MOSTRAR_ALIAS
      }else{
        this.mensajesService.mensajeError(res.mensaje_error || 'Ha ocurrido un error.');
      }
    },
    (err : HttpErrorResponse ) => {
      const error = err.error;
      this.mensajesService.mensajeError(error.mensaje_error || 'Ha ocurrido un error.');
    })
  }

  abrirDeepLink(){
    this.deeplink.abrirDeepLink(this.orden)
  }

  get formCuit() {
    return this.form.get('cuit');
  }
}

export enum StepTransferencia {
  INICIO = 0,
  MOSTRAR_ALIAS = 1,
}
