import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { IOrden } from '../../models/orden.model';
import { OrdenInfoComponent } from '../orden-info/orden-info.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { QrPixService } from '../../services/qr-pix.service';
import { TimerComponent } from '../../components/timer/timer.component';
import { Subscription, interval } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { MensajesService } from '../../services/mensajes.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'app-qr-pix',
  standalone: true,
  imports: [
    CommonModule,
    OrdenInfoComponent,
    ReactiveFormsModule,
    SharedDirectivesModule,
    SharedPipesModule,
    QRCodeModule,
    TimerComponent,
    NgxMaskDirective
  ],
  templateUrl: './qr-pix.component.html',
  styleUrl: './qr-pix.component.scss'
})
export class QrPixComponent implements OnDestroy {
  @Input('orden') orden! : IOrden;
  @Output() clickVolver = new EventEmitter<void>();

  @ViewChild(TimerComponent) timerComponent!: TimerComponent;

  public form: FormGroup;
  public step: number = StepPix.INICIO;
  public width = 292;
  public qrDisabled = false;

  public datosPix : {qrcode:string, expires_in: number, total:number, qr_id:number} | null = null;

  public consultaPixTimer : Subscription | null = null;

  public movimiento : any = null;

  constructor(
    private fb: FormBuilder,
    private qrPixService : QrPixService,
    private mensajesService : MensajesService
  ) {
    this.form = this.fb.group({
      cpf: ['', [Validators.required, Validators.pattern(/^\d+$/),Validators.minLength(11),Validators.maxLength(14)]],
    });


    // borrar
    // this.step = StepPix.EN_ESPERA;
    // this.datosPix = {
    //   "qrcode": "FAKE_EXAMPLE_QRCODE",
    //   "total": 22.73,
    //   "qr_id": 308,
    //   expires_in: 180
    // }

    //  this.step = StepPix.EXITO
    //   this.movimiento = {
    //     "id": "2024-04-18_2",
    //     "monto": "5000.00"
    //   }
  }

  ngOnDestroy(): void {
    if(this.consultaPixTimer != null){
      this.consultaPixTimer.unsubscribe();
    }
  }

  public generarQrCerrado() {
    this.qrPixService.generarQrPIX(this.orden.uuid, this.formCpf!.value).subscribe((res)=>{
      if(res.status == 'ok'){
        this.datosPix = {
          qrcode : res.qrcode,
          expires_in : res.expires_in,
          total : res.total,
          qr_id : res.qr_id,
        }

        if(this.step == StepPix.EN_ESPERA){
          this.timerComponent.inicio();
        }

        this.step = StepPix.EN_ESPERA;
        this.qrDisabled = false;

        if(this.consultaPixTimer != null){
          this.consultaPixTimer.unsubscribe();
        }
        this.consultaPixTimer = interval(5000).subscribe(()=> {
          this.consultarPix()
        })

      }else{
        this.mensajesService.mensajeError(res.mensaje_error || 'Ha ocurrido un error.');
        this.reiniciarComponente();
      }
    }
    ,(err : HttpErrorResponse ) => {
      const error = err.error;
      this.mensajesService.mensajeError(error.mensaje_error || 'Ha ocurrido un error.');
    })
  }

  timerFinalizado(valor : any){
    if(valor){
      this.qrDisabled = true;
    }
  }

  consultarPix(){
    this.qrPixService.estadoQrPIX(this.datosPix!.qr_id.toString(), this.orden.uuid).subscribe((res)=>{
      if(res.status == 'ok'){
        if(res.received > 0){
          this.consultaPixTimer!.unsubscribe();
          this.movimiento = res.movimiento;
          this.step = StepPix.EXITO;
        }
      }else{
        this.mensajesService.mensajeError(res.mensaje_error || 'Ha ocurrido un error.');
      }
    }
    ,(err : HttpErrorResponse ) => {
      const error = err.error;
      this.mensajesService.mensajeError(error.mensaje_error || 'Ha ocurrido un error.');
    })
  }

  public reiniciarComponente() {
    if(this.consultaPixTimer != null){
      this.consultaPixTimer.unsubscribe();
    }
    this.datosPix = null;
    this.consultaPixTimer = null;
    this.movimiento = null;
    this.form.reset();
    this.step = StepPix.INICIO

    //this.clickVolver.emit();
  }

  copiarQR(){
    navigator.clipboard.writeText(this.datosPix!.qrcode).then(()=>{
      this.mensajesService.mensajeExito("Copiado al portapapeles.");
    })
  }

  get formCpf() { return this.form.get('cpf'); }
}

export enum StepPix {
  INICIO = 0,
  EN_ESPERA = 1,
  EXITO = 2
}
