<div class="card-body">

  <div class="box-body mt-3">
    <app-orden-info [orden]="orden"></app-orden-info>

    <!-- INICIO -->
    <ng-container *ngIf="step == 0">
      <form class="form" [formGroup]="form">
        <div class="row">
          <div class="col-lg-4 col-xl-3">
            <div class="form-group">
              <label> Ingresá el <strong>CUIT</strong> de <strong>quien va a pagar</strong>:</label>
              <input required type="number" name="cuit" class="form-control" formControlName="cuit" mask="00000000000"
                [class.is-invalid]="(!formCuit.valid && (formCuit.touched || !formCuit.pristine ))">
              <div class="text-danger" *ngIf="(!formCuit.valid && (formCuit.touched || !formCuit.pristine ))">
                <small *ngIf="formCuit.errors['required']">* Campo requerido.</small>
                <small *ngIf="formCuit.errors['pattern']">* Formato inválido.</small>
                <small *ngIf="formCuit.errors['minlength']">* Mínimo 11 dígitos.</small>
                <small *ngIf="formCuit.errors['maxlength']">* Máximo 14 dígitos.</small>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-xl-3" id="btn-main">
            <button [disabled]="!(form.valid)" (click)="declararCuit()" class="btn btn-primary w-100">
              Pagar
            </button>
          </div>
        </div>

        <div class="row mt-3" id="cancelar-row-1">
          <div class="col-lg-4 col-xl-3 ">
            <button (click)="clickVolver.emit(); step = 0" class="btn btn-outline-primary w-100">
              Volver
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <!-- MOSTRAR ALIAS -->
    <ng-container *ngIf="step == 1">

      <div class="row">
        <div class="col-lg-4 col-xl-3">
          <div class="mb-3">
            <span style="width: 100%; display: inline-block;"> <strong> CVU:</strong> </span>
            <h5 class="text-primary" style="font-weight: 700; font-size:1.4rem; display: inline-block;">
              {{orden?.comercio.cvu}} </h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-xl-3">
          <div class="mb-3">
            <span style="width: 100%; display: inline-block;"> <strong> Alias:</strong> </span>
            <h5 class="text-primary" style="font-weight: 700; font-size:1.4rem; display: inline-block;">
              {{orden?.comercio.alias}} </h5>
          </div>
        </div>
      </div>

      <div class="row mt-3" id="cancelar-row-2">
        <div class="col-lg-4 col-xl-3">
          <button (click)="abrirDeepLink()" class="btn btn-primary w-100" >
            Pagar con TelePagos
          </button>
          <!-- <div class="card card-nav bg-primary" (click)="abrirDeepLink()" style="font-size : 0.9rem;">
            <object data="../../../assets/svg/tp_check.svg" type="image/svg+xml"></object>
            {{ 'pagar con telepagos' | uppercase }}
          </div> -->
        </div>
      </div>
      <div class="row mt-3" id="cancelar-row-2">
        <div class="col-lg-4 col-xl-3">
          <button (click)="copiarAlias()" class="btn btn-primary w-100">
            Copiar alias
          </button>
        </div>
      </div>
      <div class="row mt-3" id="cancelar-row-2">
        <div class="col-lg-4 col-xl-3">
          <button (click)="step = 0" class="btn btn-outline-primary w-100">
            Volver
          </button>
        </div>
      </div>

    </ng-container>

  </div>

</div>
