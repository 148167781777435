import {Component, HostBinding} from '@angular/core';
import packageInfo from './../../../package.json';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    @HostBinding('class') classes: string = 'main-footer';
    public appVersion = packageInfo.version;
    public currentYear: string = (new Date()).getFullYear().toString();
}


