import { Component } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { OrdenesCobroService } from '../services/ordenes-cobro.service';

@Component({
  selector: 'app-placeholder',
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent
  ],
  templateUrl: './placeholder.component.html',
  styleUrl: './placeholder.component.scss'
})
export class PlaceholderComponent {
  /**
   *
   */


  constructor(public ordenesService : OrdenesCobroService) {

  }

  get title() { return (this.ordenesService.mensajeError.includes('vencido') ? 'Link vencido!' :'Link no válido')}
  get subtext() { return (this.ordenesService.mensajeError.includes('vencido') ? 'El link al que deseás ingresar <strong>ya venció</strong>.' : 'El link al que deseás ingresar <strong>no existe</strong>.')}
}
