<div class="card-body">

  <div class="box-body mt-3">
    <ng-container *ngIf="step == 0">
      <app-orden-info [orden]="orden"></app-orden-info>

      <div class="row">
        <div class="col-lg-4 col-xl-3">

          <div class="d-flex flex-column align-items-center">
            <ng-container *ngIf="qrCode">
              <!-- <h4 class="text-primary text-center" style="font-weight: 700;">
                <span class="importe">{{ orden.importe | monto }}</span>
              </h4> -->
              <qrcode [qrdata]="qrCode" [width]="width" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
            </ng-container>

            <div *ngIf="!qrCode" [ngStyle]="{ 'width' : width + 'px' , 'height' : width + 'px' }"
              class="d-flex justify-content-center align-items-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Cargando QR...</span>
              </div>
            </div>
            <!-- <div class="w-100">
            </div> -->

          </div>
        </div>

      </div>

      <div class="row mt-3"  id="cancelar-row-2">
        <div class="col-lg-4 col-xl-3 mb-3 ">
          <button class="btn btn-outline-primary w-100" (click)="volver()">volver</button>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="step == 1">
      <div class="row mt-3" id="mensaje-exito">
        <div class="col-lg-4 col-xl-3">
          <h4 class="text-primary mb-5"><b>Pago exitoso!</b></h4>
          <h4 class="text-primary importe">{{total | monto}}</h4>
          <h6 class="" >N° de movimiento: <b>{{movimiento}}</b></h6>
        </div>
      </div>
    </ng-container>

  </div>

</div>
