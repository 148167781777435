import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {

  @Output() accion = new EventEmitter<boolean>();
  @Input() duracion: number = 0;
  view: boolean = false;
  minutos: string = '00';
  segundos: string = '00';
  timer: any;
  tiempoRestante: number = 0;
  subscription!: Subscription;

  constructor() {
  }

  ngOnInit() {
    this.inicio();
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  inicio() {
    let compareDate = new Date();
    compareDate.setTime(compareDate.getTime() + this.duracion * 1000);

    this.timer = setInterval(
      () => {
        this.view = true;
        this.timeBetweenDates(compareDate)
      }
      , 500);
  }

  timeBetweenDates(toDate : any) {
    var dateEntered = toDate;
    var now = new Date();
    var difference = dateEntered.getTime() - now.getTime();

    if (difference <= 0) {
      this.finalizado(true);
      clearInterval(this.timer);
    } else {
      var seconds = Math.floor(difference / 1000) % 60;
      var minutes = Math.floor(difference / (1000 * 60)) % 60;

      this.minutos = String(minutes).padStart(2, '0');
      this.segundos = String(seconds).padStart(2, '0');
    }
  }

  finalizado(estado : any) {
    this.minutos = '00';
    this.segundos = '00';
    this.accion.emit(estado);
  }

}
