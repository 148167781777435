import { Component, Input } from '@angular/core';
import { IOrden } from '../../models/orden.model';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';

@Component({
  selector: 'app-orden-info',
  standalone: true,
  imports: [SharedPipesModule],
  templateUrl: './orden-info.component.html',
  styleUrl: './orden-info.component.scss'
})
export class OrdenInfoComponent {
  @Input('orden') orden! : IOrden;
}
