<div class="float-right d-none d-sm-block text-primary">
  <a href="https://www.telepagos.com.ar/" target="_blank" rel="noopener noreferrer" style="margin: 0; color:inherit">
    telepagos.com.ar
  </a>
</div>

<div class="float-right d-block d-sm-none">
  v{{ appVersion }}
</div>

<div class="text-primary  d-none d-sm-block ">
  <span>Copyright &copy; {{ currentYear }} - TelePagos</span>
</div>

<div class="d-block d-sm-none ">
  <span>Plataforma de Pagos</span>
</div>


<!-- <a href="https://www.telepagos.com.ar/" target="_blank" rel="noopener noreferrer" style="margin: 0">
  telepagos.com.ar</a> -->
