import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { IOrden } from '../models/orden.model';

@Injectable({
  providedIn: 'root'
})
export class OrdenesCobroService {
  private readonly url = environment.url;
  private _orden : IOrden | null = null;
  private _mensajeError : string = "";
  private readonly mensajeErrorDefault : string  = "Link no válido!";

  constructor(
    private http : HttpClient,
    private router : Router,
  ) {
  }

  async obtener(uuid : string) : Promise<IOrden> {
    try {
      const res = await firstValueFrom(this.http.get<any>(this.url + 'plataforma_pagos/orden', { params : { uuid }}));
      if(res.status == 'ok' && res.orden !== null){
        this._orden = res.orden
      }else{
        this._orden = null;
        this._mensajeError = res.mensaje_error ;
        this.router.navigate(['error']);
      }
    } catch (error) {
      this._orden = null;
      this._mensajeError = this.mensajeErrorDefault ;
      this.router.navigate(['error']);
    }

    return { ... this._orden! };
  }

  setCuit(uuid : string, cuit : string) {
    return this.http.post<any>(this.url + 'plataforma_pagos/orden/cuit', { uuid, cuit })
  }

  get orden(){
    return (this._orden == null) ? this._orden : { ... this._orden };
  }

  get mensajeError(){ return this._mensajeError }

}
