<div class="wrapper" *ngIf="orden">
  <app-header></app-header>
  <div class="content">
    <div>
      <div class="card-body" *ngIf="metodoPago == MetodosPago.INDEFINIDO">

        <div class="box-body mt-3">
          <app-orden-info [orden]="orden"></app-orden-info>
        </div>

        <div class="separation-line secondary my-4" ></div>

        <h6 class="text-primary"><strong>Seleccioná como querés pagar</strong></h6>

        <div class="row card-navs" id="cards-nav">

          <div class="col-6 col-lg-4 col-xl-3 mb-3" *ngIf="orden.comercio.qr_interoperable">
            <div class="card card-nav bg-primary" (click)="metodoPago = MetodosPago.QR_ESTANDAR">
              <object data="../../../assets/svg/qr.svg" type="image/svg+xml"></object>
              QR
            </div>
          </div>


          <div class="col-6 col-lg-4 col-xl-3 mb-3">
            <div class="card card-nav bg-primary" (click)="metodoPago = MetodosPago.TRANSFERENCIA">
              <object data="../../../assets/svg/paperplane-white.svg" type="image/svg+xml"></object>
              Transferencia
            </div>
          </div>

          <div class="col-6 col-lg-4 col-xl-3 mb-3" *ngIf="orden.comercio.qr_pix && orden.comercio.pix_importe_valido">
            <div class="card card-nav bg-primary" (click)="metodoPago = MetodosPago.QR_PIX">
              <object data="../../../assets/svg/pix.svg" type="image/svg+xml"></object>
              QR Pix
            </div>
          </div>

          <!-- <div class="col-6 col-lg-4 col-xl-3 mb-3">
            <div class="card card-nav bg-primary" (click)="abrirDeepLink()">
              <object data="../../../assets/svg/tp_check.svg" type="image/svg+xml"></object>
              TelePagos
            </div>
          </div> -->

        </div>

      </div>

      <app-qr-pix *ngIf="metodoPago == MetodosPago.QR_PIX" [orden]="orden" (clickVolver)="metodoPago = MetodosPago.INDEFINIDO"></app-qr-pix>
      <app-transferencia *ngIf="metodoPago == MetodosPago.TRANSFERENCIA" [orden]="orden" (clickVolver)="metodoPago = MetodosPago.INDEFINIDO"></app-transferencia>
      <app-qr-estandar *ngIf="metodoPago == MetodosPago.QR_ESTANDAR" [orden]="orden" (clickVolver)="metodoPago = MetodosPago.INDEFINIDO"></app-qr-estandar>

    </div>

  </div>

  <app-footer></app-footer>

</div>

